import { createSlice } from '@reduxjs/toolkit';
import { fetchTasksListSlice, fetchReportsListSlice, fetchTaskSlice, addTaskSlice, changeAddTaskSliceStatus, addActiveTaskID, refreshActiveTaskID, deleteTaskSlice, editReportSlice, changingSaveValue, updateTestsInputData, resetTestsInputData, changeEditTaskStatus, editTaskSlice, changeDeleteTaskStatus, closeReportSlice, changeCloseReportSliceStatus } from './operations';
import { logOut } from '../auth/operations';

const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};

const handlePending = state => {
    state.isLoading = true;
    state.error = null;
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        pagination: {},
        value: [],
        reports: [],
        activeTask: [],
        activeTaskID: null,
        isLoading: false,
        error: null,
        isTaskAdd: false,
        isDeleteTask: false,
        isSavingFulfilled: false,
        isReportClose: false,
        testsInputData: []
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTasksListSlice.pending, handlePending)
            .addCase(fetchTasksListSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.value = action.payload.data;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchTasksListSlice.rejected, handleRejected)
            .addCase(fetchReportsListSlice.pending, handlePending)
            .addCase(fetchReportsListSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.reports = action.payload.data;
                state.pagination = action.payload.pagination;
                // state.isSavingFulfilled = false;
            })
            .addCase(fetchReportsListSlice.rejected, handleRejected)
            .addCase(fetchTaskSlice.pending, handlePending)
            .addCase(fetchTaskSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.activeTask = [action.payload];
            })
            .addCase(fetchTaskSlice.rejected, handleRejected)
            .addCase(addTaskSlice.pending, handlePending)
            .addCase(addTaskSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTaskAdd = true;
            })
            .addCase(addTaskSlice.rejected, handleRejected)

            .addCase(changeAddTaskSliceStatus.pending, handlePending)
            .addCase(changeAddTaskSliceStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTaskAdd = action.payload;
            })
            .addCase(changeAddTaskSliceStatus.rejected, handleRejected)

            .addCase(editReportSlice.pending, handlePending)
            .addCase(editReportSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isSavingFulfilled = true;
            })
            .addCase(editReportSlice.rejected, handleRejected)

            .addCase(editTaskSlice.pending, handlePending)
            .addCase(editTaskSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isEditTask = true;
            })
            .addCase(editTaskSlice.rejected, handleRejected)


            .addCase(changeEditTaskStatus.pending, handlePending)
            .addCase(changeEditTaskStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isEditTask = action.payload;
            })
            .addCase(changeEditTaskStatus.rejected, handleRejected)


            // .addCase(setActiveTaskID.pending, handlePending)
            .addCase(addActiveTaskID.fulfilled, (state, action) => {
                state.activeTaskID = action.payload;
            })
            .addCase(refreshActiveTaskID.fulfilled, (state, action) => {
                state.activeTaskID = action.payload;
            })

            .addCase(deleteTaskSlice.pending, handlePending)
            .addCase(deleteTaskSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.value = [...state.value.filter(el => el.id !== action.payload)];
                state.isDeleteTask = true;

            })
            .addCase(deleteTaskSlice.rejected, handleRejected)

            .addCase(changeDeleteTaskStatus.pending, handlePending)
            .addCase(changeDeleteTaskStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isDeleteTask = action.payload;
            })
            .addCase(changeDeleteTaskStatus.rejected, handleRejected)

            .addCase(closeReportSlice.pending, handlePending)
            .addCase(closeReportSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isReportClose = true;

            })
            .addCase(closeReportSlice.rejected, handleRejected)

            .addCase(changeCloseReportSliceStatus.pending, handlePending)
            .addCase(changeCloseReportSliceStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isReportClose = action.payload;
            })
            .addCase(changeCloseReportSliceStatus.rejected, handleRejected)


            .addCase(updateTestsInputData.pending, handlePending)
            .addCase(updateTestsInputData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.testsInputData = [...state.testsInputData.filter(el => (el.title && el.title !== action.payload.title)), action.payload];

            })
            .addCase(updateTestsInputData.rejected, handleRejected)

            .addCase(resetTestsInputData.pending, handlePending)
            .addCase(resetTestsInputData.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
                state.testsInputData = [];

            })
            .addCase(resetTestsInputData.rejected, handleRejected)

            .addCase(logOut.fulfilled, state => {
                state.pagination = {};
                state.value = [];
                state.reports = [];
                state.activeTask = null;
                state.activeTaskID = null;
                state.isLoading = false;
                state.error = null;
                state.isSavingFulfilled = false;
                state.testsInputData = [];
            })
            .addCase(changingSaveValue.fulfilled, state => {
                state.isSavingFulfilled = false;
            })

        
    },
});

export default tasksSlice.reducer;
