import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '../auth/operations';
import { fetchTickets, fetchTicket, addTicket, deleteTicket, updateTicketAnswer, updateCurrentTicketStatus, resetCurrentTicket, changeAddTicketStatus, changeDeleteTicketStatus, changeUpdateTicketStatus, updateTicket } from './operations';
// import { updateProfileInfo } from '../auth/operations';


const handlePending = state => {
    state.isLoading = true;
    state.error = null;
};

const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};

const ticketsSlice = createSlice({
    name: 'tickets',
    initialState: {
        pagination: {},
        items: [],
        current: [],
        isTicketEdd: false,
        isTicketDelete: false,
        isTicketUpdate: false,
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTickets.pending, handlePending)
            .addCase(fetchTickets.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.items = action.payload.data;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchTickets.rejected, handleRejected)

            .addCase(addTicket.pending, handlePending)
            .addCase(addTicket.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTicketEdd = true;
                // state.items.push(action.payload);
            })
            .addCase(addTicket.rejected, handleRejected)

            .addCase(changeAddTicketStatus.pending, handlePending)
            .addCase(changeAddTicketStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTicketEdd = action.payload;
            })
            .addCase(changeAddTicketStatus.rejected, handleRejected)

            .addCase(updateTicketAnswer.pending, handlePending)
            .addCase(updateTicketAnswer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTicketUpdate = true;
                // state.items = state.items.map(item => { if (item.id === action.payload.id) { return action.payload; } return item; })
            })
            .addCase(updateTicketAnswer.rejected, handleRejected)

            .addCase(changeUpdateTicketStatus.pending, handlePending)
            .addCase(changeUpdateTicketStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTicketUpdate = action.payload;
            })
            .addCase(changeUpdateTicketStatus.rejected, handleRejected)

            .addCase(updateTicket.pending, handlePending)
            .addCase(updateTicket.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTicketUpdate = true;
                // state.items = state.items.map(item => { if (item.id === action.payload.id) { return action.payload; } return item; })
            })
            .addCase(updateTicket.rejected, handleRejected)


            .addCase(deleteTicket.pending, handlePending)
            .addCase(deleteTicket.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                const index = state.items.findIndex(
                    item => item.id === action.payload
                );
                state.items.splice(index, 1);
                state.isTicketDelete = true

            })
            .addCase(deleteTicket.rejected, handleRejected)

            .addCase(changeDeleteTicketStatus.pending, handlePending)
            .addCase(changeDeleteTicketStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTicketDelete = action.payload;
            })
            .addCase(changeDeleteTicketStatus.rejected, handleRejected)


            .addCase(fetchTicket.pending, handlePending)
            .addCase(fetchTicket.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.current = action.payload;
            })
            .addCase(fetchTicket.rejected, handleRejected)
            .addCase(updateCurrentTicketStatus.pending, handlePending)
            .addCase(updateCurrentTicketStatus.fulfilled, (state, action) => {
                const newCurrentTicket = {...state.current};
                newCurrentTicket.status = action.payload;

                state.isLoading = false;
                state.error = null;
                state.current = newCurrentTicket;
            })
            .addCase(updateCurrentTicketStatus.rejected, handleRejected)
            .addCase(resetCurrentTicket.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
                state.current = [];
            })
            .addCase(logOut.fulfilled, state => {
                state.pagination = {};
                state.items = [];
                state.current = [];
                state.isLoading = false;
                state.error = null;
            })
    },
});

export default ticketsSlice.reducer;
